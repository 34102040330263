
import { defineComponent } from "vue";
import CodeHighlighter from "@/components/highlighters/CodeHighlighter.vue";
import { code1 } from "./data";

export default defineComponent({
  name: "basic",
  components: {
    CodeHighlighter
  },
  methods: {
    goBack() {
      console.log("go back");
    }
  },
  setup() {
    return {
      code1
    };
  }
});
